import { DropDownList } from '@progress/kendo-react-dropdowns'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import * as Sentry from '@sentry/browser'
import { INTERESTS } from 'components/add-interests/constants'
import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import * as Flash from 'components/flash'
import { HeaderComponent } from 'components/header/component'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as DateFormat from 'date-fns'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import { Dataset } from 'generic/dataset'
import { updateContactStatus, validateEmail } from 'generic/utility'
import { partition } from 'lodash'
import isEmpty from 'lodash/isEmpty'
import isNil from 'lodash/isNil'
import * as PapaParse from 'papaparse'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import * as Router from 'router'
import { FileSelector } from 'shared/components'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { uploadFile } from 'shared/utils'
import './_styles.scss'
import * as Constants from './constants'

export class AddInterestsComponentToConnect extends Component<any, any> {
  public static contextType = RasaContext

  constructor(props: any) {
    super(props)
    this.state = {
      communityId: null,
    }
  }

  public componentDidMount() {
    this.context.user.init().then(({ person, activeCommunity }) => {
      this.setState({
        communityId: activeCommunity.communityId,
      })
    })
  }

  public render() {
    return (
      <div>
        {this.state.loading
        ? (<Loading size="32"></Loading>)
        : (
          <div className="bulk-unsubscribe-wrapper">
            <UploadCSVComponent
              uploadType={INTERESTS}
              push={this.props.push}
            ></UploadCSVComponent>
          </div>
        )}
      </div>
    )
  }
}

export const AddInterestsComponent = connect(
  null,
  {
    push: Router.push,
  },
)(AddInterestsComponentToConnect)

interface ValidationStatus {
  isValid: boolean
  messages: string[]
}

const validateHeader = (props): boolean => {
  const header = props.state.headers[Number(props.field)]
  if (header) {
    if (header.key !== 'skip') {
      return props.state.headers.filter((h) => h.key === header.key).length < 2
    } else {
      return true
    }
  } else {
    return false
  }
}

const trim = (value: string) => {
  return value ? value.trim() : null
}

const createHeaderClassName = (props, header) => {
  if (header.key === 'skip') {
    return 'skip-header'
  } else {
    return validateHeader(props) ? 'valid-header' : 'invalid-header'
  }
}

const CustomHeaderCell = (props) => {
  const header = props.state.headers[Number(props.field)]
  return (
    <div className={createHeaderClassName(props, header)}>
      <DropDownList
        data={Constants.AllHeaders}
        valid={true}
        textField="description"
        dataItemKey="key"
        value={header}
        validityStyles={false}
        onChange={(e) => props.onSelect(e.target.value, Number(props.field))}
      />
    </div>
  )
}
const PreviewGrid = (props) => {
  return (
    <div>
      <div>
        <Grid data={props.state.records.slice(0, 9)}>
          {props.state.headers.map((header, i) => {
            return (
              <GridColumn
                key={i}
                width={220}
                field={i.toLocaleString()}
                headerCell={props.customHeaderCell}
                cell={props.customCell}
              />
            )
          })}
        </Grid>
      </div>
    </div>
  )
}
interface UploadCSVComponentProps {
  uploadType: string
  push?: (location: string) => any
}
interface UploadCSVComponentState {
  fileCountMsg: string
  importing: boolean
  hasHeaders: boolean
  headers: any[]
  nextClicked: boolean
  overLimitMessage: string
  isBulkRequestIsPending: boolean
  records: any[]
  selectedFile: any
}

export class UploadCSVComponent extends Component<
  UploadCSVComponentProps,
  UploadCSVComponentState
> {
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null
  private emailColumnCountObj: any
  constructor(props: any) {
    super(props)

    this.state = {
      fileCountMsg: '',
      importing: false,
      hasHeaders: true,
      headers: [],
      isBulkRequestIsPending: false,
      nextClicked: false,
      overLimitMessage: '',
      records: [],
      selectedFile: null,
    }

    this.reset = this.reset.bind(this)
    this.onFileSelect = this.onFileSelect.bind(this)
    this.selectHeader = this.selectHeader.bind(this)
    this.importInterests = this.importInterests.bind(this)
  }

  public componentDidMount = () => {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore
      .getValue(SharedKeys.activeCommunity)
      .then((activeCommunity) => {
        this.communityId = activeCommunity.communityId
        this.getPendingBulkUploadRuns()
      })
  }

  public render() {
    const validHeaders = this.validateHeaders()
    const details = Constants.UploadPageDetails[this.props.uploadType]
    return (
      <div className="import-csv">
        {!this.state.importing
        ? (
          <div className="upload-container">
            {(this.state.nextClicked || details.goBack) && (
              <div className="go-back" onClick={() => this.goBack()}>
                &lt; &nbsp; Go back
              </div>
            )}
            <HeaderComponent
              title={'CONTACTS'}
              subTitle={details.header}
              description={[details.description]}
            />
            {!this.state.records.length && (
              <FileSelector
                sampleFileLocation={
                  Constants.INTERESTS_IMPORT_SAMPLE_FILE_LOCATION
                }
                onSelect={this.onFileSelect}
              ></FileSelector>
            )}
            <div className="file-count">{this.state.fileCountMsg}</div>
            {!!this.state.records.length && (
              <div className="headers-checkbox-wrapper">
                <p className="headers-checkbox-text">
                  {' '}
                  My file contains headers
                </p>
                <input
                  type="checkbox"
                  className="headers-checkbox"
                  checked={this.state.hasHeaders}
                  onChange={() => this.toggleHeadersCheckBox()}
                />
              </div>
            )}
            {!this.state.records.length && (
              <div className="next-wrapper">
                <Button
                  outline
                  onClick={() =>
                    this.setState({ nextClicked: true }, this.loadFile)
                  }
                  disabled={!this.state.selectedFile}
                >
                  Next
                </Button>
              </div>
            )}
            <div className="headers-validation-messages-wrapper">
              {!!this.state.records.length &&
                !validHeaders.isValid &&
                validHeaders.messages.map((message, i) => (
                  <span key={i} className="headers-validation-message">
                    {message}
                  </span>
                ))}
            </div>
            {!!this.state.records.length && (
              <div>
                <div className="import-grid-container">
                  <PreviewGrid
                    state={this.state}
                    customHeaderCell={this.customHeaderCell}
                    customCell={this.customCell}
                    importContacts={this.importInterests}
                    validHeaders={validHeaders}
                  ></PreviewGrid>
                </div>
                <Button
                  outline
                  className="import-button"
                  onClick={() => this.importInterests()}
                  disabled={this.uploadIsDisabled()}
                >
                  {details.button}
                </Button>
              </div>
            )}
          </div>
        )
        : <Loading size="32"></Loading>
        }
      </div>
    )
  }

  private getPendingBulkUploadRuns = () => {
    return new Dataset()
      .loadCommunityDataset('newsletterRuns', this.communityId, [
        { param: 'runType', value: 'contact' },
        { param: 'status', value: 'Pending' },
      ])
      .then((pendingRuns) => {
        if (
          pendingRuns[0].length &&
          new Date() <
            DateFormat.addHours(new Date(pendingRuns[0][0].start_date), 6)
        ) {
          this.setState({
            isBulkRequestIsPending: true,
          })
        }
      })
  }

  private uploadIsDisabled = (): boolean => {
    const toUploadContactsCount = this.state.records.length
    return (!toUploadContactsCount || !this.validateHeaders().isValid || this.state.importing)
  }

  private goBack = () => {
    if (this.state.nextClicked) {
      this.reset()
    } else {
      this.props.push(`${DashboardMenuOption.contacts}/add-contacts`)
    }
  }

  private customHeaderCell = (props) => {
    return (
      <CustomHeaderCell
        {...this.props}
        field={String(props.field)}
        onSelect={this.selectHeader}
        state={this.state}
      />
    )
  }

  private customCell = (props) => {
    const header = this.state.headers[props.columnIndex].key
    return (
      <td className={header === 'skip' ? 'skipped' : 'not-skipped'}>
        {props.dataItem[props.columnIndex]}
      </td>
    )
  }

  private selectHeader(selectedHeader, columnIndex) {
    const newHeaders = [...this.state.headers]
    newHeaders.splice(columnIndex, 1, selectedHeader)
    this.setState({
      headers: newHeaders,
    })
  }

  private getEmailColumnsCountObj(records) {
    return records.reduce((acc, cur) => {
      cur.forEach((value, i) => {
        if (validateEmail(value)) {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          acc[i] ? (acc[i] += 1) : (acc[i] = 1)
        }
      })
      return acc
    }, {})
  }

  private getEmailColumnIndex(records) {
    this.emailColumnCountObj = this.getEmailColumnsCountObj(records)
    if (Object.keys(this.emailColumnCountObj).length) {
      const values: any = Object.values(this.emailColumnCountObj)
      const mostOccurences = Math.max(...values)
      const result = Object.keys(this.emailColumnCountObj).filter(
        (column) => this.emailColumnCountObj[column] === mostOccurences,
      )
      return result
    } else {
      return null
    }
  }

  private reset() {
    this.setState({
      fileCountMsg: '',
      importing: false,
      hasHeaders: true,
      headers: [],
      nextClicked: false,
      records: [],
      selectedFile: null,
      overLimitMessage: '',
    })
  }

  private onFileSelect(f: any) {
    if (f) {
      this.setState({
        selectedFile: f,
      })
    }
  }

  private loadFile() {
    if (this.state.selectedFile) {
      const reader = new FileReader()
      reader.onload = (e: any) => {
        const data = this.arrayBufferToString(e.target.result)
        if (!isNil(data) && !isEmpty(data)) {
          const parseResult = PapaParse.parse(data)
          const { csvHeaders, csvRecords } = this.splitHeadersAndRecords(parseResult)
          const partitionedRecords = partition(csvRecords, (record: any) => this.containsEmail(record))
          const invalidRecordsCount = partitionedRecords[1].length
          const finalRecords = partitionedRecords[0].map((record: any) => record.map((v) => trim(v)))
          const finalHeaders = csvHeaders // this.removeNeedless(csvHeaders)

          this.setState(
            { records: finalRecords, headers: finalHeaders },
            () => {
              this.setState({
                fileCountMsg: this.generateMessage(invalidRecordsCount),
              })
            },
          )
        }
      }
      reader.readAsArrayBuffer(this.state.selectedFile)
    }
  }

  private splitHeadersAndRecords(parseResult) {
    let csvRecords = parseResult.data.slice(1)
    let csvHeaders = Array(csvRecords[0].length).fill('')
    // if 1st row does not contain a valid email address => assume 1st row is the header and format that header
    if (!this.containsEmail(parseResult.data.slice(0, 1)[0])) {
      csvHeaders = this.formatCsvHeaders(
        parseResult.data.slice(0, 1)[0],
        csvRecords,
      )
      this.setState({
        hasHeaders: true,
      })
      // else if 1st row does contain a valid email address => assume 1st row is data
    } else {
      csvRecords = parseResult.data.slice(0)
      csvHeaders = this.formatCsvHeaders(csvHeaders, csvRecords)
      this.setState({
        hasHeaders: false,
      })
    }
    return { csvHeaders, csvRecords }
  }

  private generateMessage(invalidCount) {
    return !this.state.records.length
      ? 'Sorry, we cound not find any valid contact.'
      : `We found ${pluralize(
          'valid contact',
          this.state.records.length || 0,
          true,
        )}! ${
          !invalidCount
            ? ''
            : `We removed ${pluralize(
                'contact',
                invalidCount || 0,
                true,
              )} without an email address.`
        }`
  }

  private formatCsvHeaders(csvHeaders, csvRecords) {
    let finalCsvHeaders
    const emailColumnIndex = this.getEmailColumnIndex(csvRecords)
    if (emailColumnIndex) {
      // headers and data need to be of same length, we normalize here for some edge case where they're not
      finalCsvHeaders =
        csvHeaders.length === csvRecords[0].length
          ? [...csvHeaders]
          : Array(csvRecords[0].length)
              .fill('')
              .map((v, i) => (csvHeaders[i] ? csvHeaders[i] : v))
      finalCsvHeaders.splice(emailColumnIndex, 1, 'Email')
    } else {
      finalCsvHeaders = Array(csvRecords[0].length)
        .fill('')
        .map((v, i) => (csvHeaders[i] ? csvHeaders[i] : v))
    }

    finalCsvHeaders = finalCsvHeaders.map((header) => {
      const trimmedHeader = header.toLowerCase().replace(/\s|-|_/g, '')
      let newHeader: Constants.HeaderChoice = Constants.SkipHeader
      Constants.AllHeaders.forEach((standardHeader) => {
        if (standardHeader.alternatives.includes(trimmedHeader)) {
          newHeader = standardHeader
        }
      })
      return newHeader
    })
    return finalCsvHeaders
  }

  private containsEmail(record: []) {
    return !!record.filter((value: string) => validateEmail(value.trim()) ).length
  }

  private validateHeaders(): ValidationStatus {
    const result = {
      isValid: true,
      messages: [],
    }
    //Email must be present
    if (!this.state.headers.filter((header) => header.key === 'email').length) {
      result.isValid = false
      result.messages.push('Headers must contain \'Email\'')
    }
    //Interests must be present
    if (!this.state.headers.filter((header) => header.key === 'interests').length) {
      result.isValid = false
      result.messages.push('Headers must contain \'Interests\'')
    }
    // All columns must have a header
    if (
      this.state.headers.filter((header) => ['', null].includes(header.key))
        .length
    ) {
      result.isValid = false
      result.messages.push('All columns must have a header')
    }
    // No header value can be duplicated except Skip

    const summary = this.state.headers.reduce((acc, cur) => {
      if (cur.key !== 'skip') {
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        acc[cur.key] ? (acc[cur.key] += 1) : (acc[cur.key] = 1)
      }
      return acc
    }, {})
    const occurences: number[] = Object.values(summary)
    if (Math.max(...occurences) !== 1) {
      result.isValid = false
      result.messages.push('Each header must be unique')
    }
    return result
  }

  private toggleHeadersCheckBox() {
    if (this.state.hasHeaders) {
      const newRecords = [...this.state.records]
      const newFirstRow = this.state.headers.map((header) => header.key)
      newRecords.unshift([...newFirstRow])
      this.setState(
        {
          headers: Array(this.state.records[0].length).fill(
            Constants.SkipHeader,
          ),
          records: newRecords,
          hasHeaders: false,
        },
        () => this.changeCountMessage(),
      )
    } else {
      const newHeaders = this.state.records[0].map((header) => {
        const trimmedHeader = header.toLowerCase().replace(/\s|-|_/g, '')
        let newHeader: Constants.HeaderChoice = Constants.SkipHeader
        Constants.AllHeaders.forEach((standardHeader) => {
          if (standardHeader.alternatives.includes(trimmedHeader)) {
            newHeader = standardHeader
          }
        })
        return newHeader
      })
      this.setState(
        {
          headers: [...newHeaders],
          records: this.state.records.slice(1),
          hasHeaders: true,
        },
        () => this.changeCountMessage(),
      )
    }
  }

  private importInterests() {
    if (this.state.records.length && this.state.headers) {
      uploadFile(
        this.communityId,
        'csv',
        `contacts/${this.props.uploadType || 'import'}`,
        this.state.selectedFile,
      ).then((filePath) => {
        if (!filePath) {
          this.context.store.dispatch(Flash.showFlashMessage(Constants.FILE_UPLOAD_ERROR_MSG))
          return
        }
        const finalRecords = []
        this.state.records.forEach((record) => {
          const subscriberRecord = record.reduce((acc, field, index) => {
            const header = this.state.headers[index]
              ? this.state.headers[index].key
              : null
            if (header && header !== 'skip' && field) {
              acc[header] = field
            }
            return acc
          }, {})
          if (subscriberRecord.email !== '') {
            finalRecords.push(updateContactStatus(subscriberRecord, this.props.uploadType))
          }
        })
        this.setState({importing: true})
        const fileName = this.state.selectedFile.name
        const url: string = AjaxWrapper.getServerUrl() + `/${this.communityId}/interests-upload`
        AjaxWrapper.ajax(url, HttpMethod.POST, {
          subscribers: finalRecords,
          uploadType: this.props.uploadType,
          filePath,
          fileName,
        }).then(() => {
          this.setState({ importing: false })
          this.props.push(`${DashboardMenuOption.contacts}`)
        })
      }).catch((error) => {
        Sentry.captureException(error)
        this.context.store.dispatch(Flash.showFlashMessage(Constants.FILE_UPLOAD_ERROR_MSG))
      })
    }
  }

  private changeCountMessage() {
    this.setState({
      fileCountMsg: `We found ${this.state.records.length} ${ this.state.records.length > 1 ? 'contacts' : 'contact'}!`,
    })
  }

  private arrayBufferToString(buffer: ArrayBuffer): string {
    try {
      let data = ''
      const bytes = new Uint8Array(buffer)
      let index = 0
      if (bytes[0] === 0xef && bytes[1] === 0xbb && bytes[2] === 0xbf) {
        // BOM char throws of the code that evaluates CSV headers.  SO make sure to strip it - EFBBBF
        index = 3
      }
      for (; index < bytes.byteLength; index++) {
        data += String.fromCharCode(bytes[index])
      }
      return data
    } catch (err) {
      return null
    }
  }
}
