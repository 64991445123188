import { DashboardMenuOption } from 'components/dashboard-menu/constants'
import { HeaderIcon } from 'components/icons/header-icon'
import { Loading } from 'components/loading'
import { RasaContext } from 'context'
import * as GA from 'google-analytics'
import pluralize from 'pluralize'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Button } from 'reactstrap'
import * as Router from 'router'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import { ADD } from 'shared_server_client/constants'
import { isOverContactLimit } from 'shared_server_client/types/billing_plan'
import { Schedule } from 'shared_server_client/types/schedule'
import { enterpriseUsage, UsageStats } from 'shared_server_client/types/usage_stats'
import { UpgradeAnchorLink } from '../link'
import * as Constants from './constants'
import './styles.css'

const usageNotifications = {
  messageUpgradeRecommended: <div>Based on your current schedule and number of contacts to be uploaded,&nbsp;
    you may go over the limit. We recommend you <b>upgrade your account </b>to avoid any interruption.</div>,
  contractUpgradeRecommended: <div>This import may put you over the number of contacts allowed by your plan.
    We recommend you contact us to avoid any service disruption.</div>,
}

const FrequencyAndProjections = (props) => {
  const projectedContacts = props.totalProjectedContacts > 0 ? props.totalProjectedContacts : 0
  const isOverLimit: boolean = props.state.usageStats.currentPlanIncludedContacts !== -1 &&
                              !props.state.usageStats.hasUnlimitedContacts &&
                              props.totalProjectedContacts > props.state.usageStats.currentPlanIncludedContacts
  return (
    <div className="frequency-and-projections-wrapper">
      <div className="frequency-wrapper">
        <h6>You are uploading {pluralize('contact', (props.contactsCount || 0), true)}</h6>
      </div>
      {!props.isFullListReplacement ?
      <div className="projections-wrapper">
        <h6>After this upload, your projected total contact count will be:</h6>
        <div className="projections-value-of-allowed-wrapper">
          <div className="projections-value"
              style={{color: isOverLimit ? '#ee7470' : 'teal'}}>
            {projectedContacts.toLocaleString()}
          </div>
          {!props.state.usageStats.hasUnlimitedMessages &&
          <div className="allowed-value">
            of &nbsp;
            <b>{props.state.usageStats.currentPlanIncludedMessages.toLocaleString()}</b>
            &nbsp;
            allowed
          </div>}
        </div>
        {isOverLimit &&
        <h6>This may increase your contact pricing on your current plan.</h6>}
      </div> : null}
    </div>
  )
}

const MessageUsageAcknowledgement = (props) => {
  return (
    <div className="usage-warning-acknowledgement">
      <div className="headers-checkbox-wrapper">
        <input type="checkbox" className="usage-warning-checkbox"
          checked={props.state.hasHeaders}
          onChange={() => props.toggleUsageWarningCheckBox()}/>
        <p className="usage-warning-checkbox-text">
          I understand that if I schedule more newsletters than
          {props.state.usageStats.currentPlanIncludedMessages
          .toLocaleString()} messages,&nbsp;the additional messages will not be sent</p>
      </div>
    </div>
  )
}

const ContactUsageAcknowledgement = (props) => {
  return (
    <div className="usage-warning-acknowledgement">
      <div className="headers-checkbox-wrapper">
        <input type="checkbox" className="usage-warning-checkbox"
          checked={props.state.hasHeaders}
          onChange={() => props.toggleUsageWarningCheckBox()}/>
        <p className="usage-warning-checkbox-text">
          I understand that if I try to send to more than&nbsp;
          {props.state.usageStats.currentPlanMaxContacts
          .toLocaleString()} contacts, the additional messages may not be sent.</p>
      </div>
    </div>
  )
}

interface State {
  billingData: any,
  communityId: string | number,
  loading: boolean,
  schedule: Schedule,
  usageAcknowledged: boolean,
  usageStats: UsageStats,
}

interface Props {
  contactsCount: number,
  isFullListReplacement?: boolean,
  push: any,
  uploadType: string,
}

class UploadedContactsComponentToConnect extends Component<Props, State> {
  public static contextType = RasaContext
  private sharedStore: SharedStore

  constructor(props: Props) {
    super(props)

    this.state = {
      billingData: null,
      communityId: null,
      loading: true,
      schedule: null,
      usageAcknowledged: false,
      usageStats: enterpriseUsage,
    }
    this.toggleUsageWarningCheckBox = this.toggleUsageWarningCheckBox.bind(this)
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.setState({
        communityId: activeCommunity.communityId,
        loading: false,
        schedule: activeCommunity.nextIssue ? activeCommunity.nextIssue.schedule : {},
        usageStats: activeCommunity.billingInfo.usageStats,
      })
      if (activeCommunity.billingInfo.currentPlan.name === 'Enterprise'
        || this.props.uploadType === Constants.UNSUBSCRIBE
      ) {
        // TODO: should we refresh the page?
        this.props.push(DashboardMenuOption.contacts)
      }
    })
  }

  public render() {
    const details = Constants.UploadPageDetails[this.props.uploadType]
    return (
      <div>
      { this.state.loading ? <Loading size="32"></Loading> :
        <div className="uploaded-contacts">
          <div className="header">
            <div className="icon-and-title-wrapper">
              <HeaderIcon name={this.props.uploadType}></HeaderIcon>
              <h4 className="header-title">{details.header}</h4>
            </div>
          </div>
        {this.isOverMessageLimit() &&
          <div className="after-upload-notification">
            {usageNotifications.messageUpgradeRecommended}
          </div>
        }
        {this.isOverContactLimit() &&
          <div className="after-upload-notification">
            {usageNotifications.contractUpgradeRecommended}
          </div>
        }
          <FrequencyAndProjections isFullListReplacement={this.props.isFullListReplacement} contactsCount={this.props.contactsCount}
            totalProjectedContacts={this.calculateProjectedContacts()} state={this.state}/>
          {this.isOverMessageLimit() &&
            <MessageUsageAcknowledgement state={this.state}
                                  toggleUsageWarningCheckBox={this.toggleUsageWarningCheckBox}>
            </MessageUsageAcknowledgement>
          }
          {this.isOverContactLimit() &&
            <ContactUsageAcknowledgement state={this.state}
                                  toggleUsageWarningCheckBox={this.toggleUsageWarningCheckBox}>
            </ContactUsageAcknowledgement>
          }
          { this.isOverContactLimit() || this.isOverMessageLimit() ?
            <div>
              <div className="next-wrapper">
                <Button outline
                  onClick={() => this.props.push(DashboardMenuOption.contacts)}
                  disabled={!this.state.usageAcknowledged}
                >Next
                </ Button>
              </div>
              <UpgradeAnchorLink source={GA.UpgradeSource.CsvMessageLimit}>
                I would like to upgrade now
              </UpgradeAnchorLink>
            </div>
        : <div>
            <div className="next-wrapper">
              <Button outline
                onClick={() => this.props.push(DashboardMenuOption.contacts)}
              >Next
              </ Button>
            </div>
          </div>
        }
        </div>
      }
      </div>
    )
  }

  private isOverMessageLimit() {
    return !this.state.usageStats.hasUnlimitedMessages &&
           this.calculateProjectedMessages() > this.state.usageStats.currentPlanIncludedMessages
  }

  private isOverContactLimit() {
    return isOverContactLimit(this.state.usageStats, this.props.contactsCount)
  }

  private toggleUsageWarningCheckBox() {
    this.setState({
      usageAcknowledged: !this.state.usageAcknowledged,
    })
  }

  private calculateProjectedMessages() {
    const actualMessages = this.state.usageStats.numActualMessages
    const projectedSubscriberCount = this.props.uploadType === ADD ?
      this.state.usageStats.subscriberCount + this.props.contactsCount :
      this.state.usageStats.subscriberCount - this.props.contactsCount
    const projectedIssues = this.state.usageStats.numProjectedIssues
    const projectedTotalMessages = actualMessages + projectedIssues * projectedSubscriberCount
    return projectedTotalMessages
  }

  private calculateProjectedContacts() {
    const projectedSubscriberCount = this.props.uploadType === ADD ?
      this.state.usageStats.contactCount + this.props.contactsCount :
      this.state.usageStats.contactCount - this.props.contactsCount
    return projectedSubscriberCount
  }
}

export const UploadedContactsComponent = connect(
  null,
  {
    push: Router.push,
  },
)(UploadedContactsComponentToConnect)
