import { sizedUrl } from 'app/utils'
import classnames from 'classnames'
import { Attachment } from 'components/icons/attachment'
import { FrameLandscapeIcon } from 'components/icons/frame-landscape'
import { UrlInput } from 'components/input/component'
import { Loading } from 'components/loading'
import * as Validation from 'components/validation/validations'
import { RasaContext } from 'context'
import { AjaxWrapper, HttpMethod } from 'generic/ajaxWrapper'
import * as React from 'react'
import { connect } from 'react-redux'
import { Col, Row } from 'reactstrap'
import * as Router from 'router'
import { SharedKeys, SharedStore } from 'shared/data-layer/sharedStore'
import * as Modals from 'shared/modals'
import { IMAGE } from 'shared_server_client/constants'
import * as Constants from './constants'
import './styles.scss'

export const INVALID_IMAGE_URL_MESSAGE = 'Please double check the url is linked to an image'
export const SERVER_ERROR_MESSAGE = 'Something went wrong, try again or contact Customer Success'

export class AddImageModalComponent extends Modals.GenericModalComponent<
  Constants.AddImageModalProps, Constants.AddImageModalState> {

  public static key: string = 'addImageModal'
  public static contextType = RasaContext
  private sharedStore: SharedStore
  private communityId: string = null

  constructor(props: Constants.AddImageModalProps) {
    super(props, AddImageModalComponent.key, 'Add Image')
    this.state = {
      errorMessage: '',
      imageUrl: '',
      imageName: '',
      imageHeight: '',
      imageWidth: '',
      isEditingImage: false,
      isUploading: false,
      isDirty: false,
      sourceUrl: '',
      validUrl: true,
      useUrl: false,
      isSaving: false,
    }
    this.uploadImage = this.uploadImage.bind(this)
    this.updateProperty = this.updateProperty.bind(this)
  }

  public componentDidMount() {
    this.sharedStore = SharedStore.instance(this.context)
    this.sharedStore.getValue(SharedKeys.activeCommunity)
    .then((activeCommunity) => {
      this.communityId = activeCommunity.communityId
    })
  }

  public componentDidUpdate(prevProps: any, prevState: any) {
    const data = this.props.data.addImageModal.data
    if (data.imageUrl !== prevProps.data.addImageModal.data.imageUrl ||
        data.imageWidth !== prevProps.data.addImageModal.data.imageWidth ||
        data.imageHeight !== prevProps.data.addImageModal.data.imageHeight) {
      if (data.imageUrl || data.imageWidth || data.imageHeight) {
        this.setState({
          imageUrl: data.imageUrl,
          imageWidth: data.imageWidth,
          imageHeight: data.imageHeight,
          validUrl: Validation.validateUrl(data.imageUrl).valid,
          useUrl: true,
        })
      } else {
        this.setState({
          imageUrl: '',
        })
      }
    }
  }

  protected renderChildren(data: any) {
    const { imageUrl } = data
    return (
      this.state.isSaving
      ? <Loading size="64"/>
      :
      <div className="add-image-modal-body">
        <div className="body-container">
          <div className="left-side-container">
            <div className="first-row-container">
              <h4>Image</h4>
              {this.renderFirstRow(data)}
            </div>
          </div>
          <div className="right-side-container">
            <h5>Preview</h5>
        { this.state.isUploading
          ? <Loading size="64" />
          : <div className="preview-container">
          { imageUrl
            ? <img src={sizedUrl(imageUrl, 250, 400)}/>
            : <FrameLandscapeIcon svgWidth={400} svgHeight={250}/>
          }
            </div>
        }
          </div>
        </div>
      </div>
    )
  }

  protected doSave(data: any): Promise<any> {
    const { imageUrl, imageWidth, imageHeight } = this.state
    return this.props.onSave(
      data,
      {
      imageUrl,
      imageWidth,
      imageHeight,
    })
  }

  protected fetch = (inputUrl: string): Promise<any> => {
    const imageValidateEndpoint: string = AjaxWrapper.getServerUrl() + '/image/validate-url'
    return AjaxWrapper.ajax(imageValidateEndpoint, HttpMethod.POST, {url: inputUrl})
  }

  protected updateProperty(property, value) {
    this.props.updateModal(AddImageModalComponent.key, {
      name: property,
      value,
    })
    this.setState({ isDirty: true })
  }

  protected validateFetch = (inputUrl: string) => {
    this.setState({isUploading: true})
    return this.fetch(inputUrl)
    .then((result: any) => {
      if (result) {
        this.setState({ isUploading: false })
        this.updateProperty('imageUrl', inputUrl)
      } else {
        this.setState({
          errorMessage: INVALID_IMAGE_URL_MESSAGE,
          isUploading: false,
          validUrl: false,
        })
      }
    })
    .catch((err) => {
        this.setState({
          errorMessage: SERVER_ERROR_MESSAGE,
          isUploading: false,
          validUrl: false,
        })
    })
  }

  protected handleKeyPress = (target: any) => {
    if (target.charCode === 13 && this.state.validUrl) {
      this.validateFetch(this.state.sourceUrl)
    }
  }

  protected updateSourceUrl = (e) => {
    this.validateUrl(e.target.value)
    this.setState({ sourceUrl: e.target.value })
  }

  protected validateUrl = (url: string) => {
    this.setState({
      errorMessage: '',
      validUrl: this.state.useUrl ? Validation.validateUrl(url).valid : Validation.validateImageUrl(url).valid,
    })
  }

  private renderFirstRow = (data) => {
    return <div className={classnames('image-loader-and-buttons-container', !!this.state.errorMessage ? 'add-3rem-margin-bottom' : '')}>
              {this.renderInputBox(data)}
              <div className="buttons-container">
                {data.typeId
                ? this.renderFirstRowEdit(data)
                : this.renderFirstRowNew(data)
                }
              </div>
          </div>
  }

  private renderUploadAndCancel = (data) => {
    return <div>
      <span
        className={classnames('upload-btn',
        { 'clickable-item': this.state.validUrl, 'not-allowed': !this.state.validUrl })}
        onClick={() => this.validateFetch(this.state.sourceUrl)}>Upload
      </span>
      <span
        className={classnames('delete-btn', { 'clickable-item': true })}
        onClick={this.cancelUpload}>Cancel
      </span>
    </div>
  }

  private renderBrowseAndUseUrl = (data) => {
    return <div>
      <div className="browse-delete">
        <span className="browse-btn clickable-item">
          <label htmlFor="image" className="clickable-item">
            Browse
          </label>
          <input type="file"
                className="hidden"
                onChange={(e) => this.uploadImage(e.target.files[0])}
                id="image"
                name="image"
                accept="image/png, image/jpeg, image/gif">
          </input>
        </span>
        <span
        className="use-url-btn clickable-item"
        onClick={this.useUrl}>Use URL</span>
      </div>
    </div>
  }

  private renderFirstRowNew = (data) => {
    if (!this.state.useUrl) {
      return this.renderBrowseAndUseUrl(data)
    } else {
      return this.renderUploadAndCancel(data)
    }
  }

  private renderFirstRowEdit = (data) => {
    if (!data.isDirty && !this.state.isEditingImage && (!this.state.useUrl)) {
      return <span
        className={classnames('change-btn', { 'clickable-item': true })}
        onClick={this.onChangeClick}>Change
      </span>
      } else {
          if (this.state.useUrl) {
            return this.renderUploadAndCancel(data)
            } else {
              if (this.state.isEditingImage) {
                return this.renderBrowseAndUseUrl(data)
              }
            }
      }
  }

  private renderInputBox = (data) => {
    if (this.state.useUrl) {
      return <div>
              <Row form>
                <Col md={12}>
                  <UrlInput
                    errorMessage={this.state.errorMessage}
                    onChange={this.updateSourceUrl}
                    onKeyPress={this.handleKeyPress}
                    placeholder={'Please input your image url here'}
                    valid={this.state.validUrl}
                    value={this.state.sourceUrl || data.imageUrl}/>
                </Col>
              </Row>
            </div>
    } else {
      return <div className="file-name-container">
        <Attachment svgWidth={20} svgHeight={20}/>
        <div className="filename-text">{!data.imageUrl ? 'No image attached' : this.state.imageName}</div>
      </div>
    }
  }

  private uploadImage = (image: any) => {
    this.setState({
      isUploading: true,
      useUrl: false,
    })
    const formData = new FormData()
    formData.append(IMAGE, image)
    const url: string = `${AjaxWrapper.getServerUrl()}/${this.communityId}/image`
    return AjaxWrapper.ajax(url, HttpMethod.POST, formData, null)
      .then((hostedImage: any) => {
        this.setState({
          isUploading: false,
        })
        this.updateProperty('imageUrl', hostedImage.url)
        this.updateProperty('imageWidth', hostedImage.width)
        this.updateProperty('imageHeight', hostedImage.height)
      })
      .catch((error) => {
        this.setState({
          isUploading: false,
        })
      })
  }

  private cancelUpload = () => {
    this.setState({imageHeight: '', imageWidth: '', imageUrl: '', useUrl: false, errorMessage: '',
                    sourceUrl: '', validUrl: false})
    this.updateProperty('imageUrl', '')
    this.updateProperty('imageHeight', '')
    this.updateProperty('imageWidth', '')
    this.updateProperty('imageTitle', '')
  }

  private onChangeClick = () => {
    this.setState({isEditingImage: true})
    this.updateProperty('imageUrl', '')
    this.updateProperty('imagHeight', '')
    this.updateProperty('imageWidth', '')
  }

  private useUrl = () => {
    this.setState({useUrl: true})
  }

}

export const AddImageModal = connect(
  null,
  {
    push: Router.push,
  },
)(AddImageModalComponent)
